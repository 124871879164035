import { Flex, Heading, Button, Text, Box, Center, HStack, Icon } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import Lottie from "lottie-react";
import animationData from "./lottie/Tick.json";

export default function complete() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };


    return (
        <div>

                <Lottie options={defaultOptions}
                    height={100}
                    width={100}
                  />


            <Flex direction="column" align="center" justify="center" maxWidth="400px" height="100vh" margin="auto" mx={10}>

                <Heading as="h1" size="lg" mb={6}>
                    Personalise iLancaster
                </Heading>

                <Button colorScheme="red" variant="solid" size="md" mt="4" px={9}>
                    Done
                </Button>
            </Flex>
        </div>
    );
};