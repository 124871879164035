import React, { useState} from "react";
import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import companyData from "./companies";
import CompanyBubble from "./CompanyBubble";
import { Heading, Text, Flex, VStack, CircularProgress, CircularProgressLabel, Button, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Selection(props) {

  const [selectedBubbles, setSelectedBubbles] = useState([]);

  const getStockBubbles = () => {
    return companyData.slice(0, 50).map((company, i) => {
      return <CompanyBubble {...company} key={i} changeSelected={ symbol => changeSelected(symbol) } />;
    });
  };

  const changeSelected = (symbol) => {
    if (selectedBubbles.includes(symbol)) {
      setSelectedBubbles(selectedBubbles.filter((s) => s !== symbol));
    } else if (selectedBubbles.length === 0) {
      setSelectedBubbles([symbol]);
    } else {
      setSelectedBubbles([...selectedBubbles, symbol]);
    }
  };

  const numberSelected = selectedBubbles.length;
  const youvalue = numberSelected * 10;
  const showNext = numberSelected >= 10;
  console.log(selectedBubbles);


  const stockBubbles = getStockBubbles();

  const [options, setOptions] = useState({
    size: 130,
    minSize: 1,
    gutter: 10,
    provideProps: true,
    numCols: 10,
    fringeWidth: 70,
    yRadius: 130,
    xRadius: 220,
    cornerRadius: 1,
    showGuides: false,
    compact: true,
    gravitation: 5,
  });


  

  return (
    <React.Fragment>

      <Flex align="center" justify="center" direction="column" height={"90vh"} width={"100%"} my={"10%"}>

        <VStack spacing={4} m={4} maxW="400px" align={"center"}>
          <Heading as="h1" size="lg" >
            Tell us what you're into.
          </Heading>

          <Text fontSize='sm'>
            Tap once on the interests you like, or twice on the ones you love. Press and hold the ones you don’t.
          </Text> 
        </VStack>

        
        <BubbleUI className="bubbleUI" options={options}>
          {stockBubbles}
        </BubbleUI>

        <Text fontSize='sm'>
          {numberSelected} selected
        </Text>

        <Flex judstify="space-between" maxW="400px" align={"center"}>
          
          <Link to="/">
            <Button variant="ghost" colorScheme="blue" mr={8}>
                Back
            </Button>
          </Link>


          <CircularProgress value={youvalue} size='80px' thickness='4px' color={showNext ? "green.300" : "blue.300"}>
            <CircularProgressLabel fontSize='xl'>You</CircularProgressLabel>
          </CircularProgress>

          <Link to="/complete">
            <Button variant="ghost" colorScheme="blue" ml={8} disabled={!showNext}>
                Next
            </Button>
          </Link>

        </Flex>

      </Flex>

    </React.Fragment>
  );
}