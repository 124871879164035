import React, { useState } from "react";

import { BiBus } from "react-icons/bi";

export default function CompanyBubble(props) {

  const [selected, setSelected] = useState(false);

  const bubblePressed = () => {
    props.changeSelected(props.symbol);
    setSelected(!selected);
  };

  var imageURL = 'https://source.unsplash.com/featured/500x500'

  // console.log(props);
  return (
    <div>
      {!selected && (
      <div
        style={{
          backgroundColor: "#EB3737",
          width: "120px",
          height: "120px",
          opacity: 0.9
        }}
        className={"companyBubble"}
        onClick={bubblePressed}
        on
      >
        {true ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              transition: "opacity 0.1s ease",
              opacity: props.bubbleSize > 50 ? 1 : 0,
              pointerEvents: "none",
            }}
          >
            <BiBus size={"30px"} color={props.textColor} />
            <p
              style={{
                color: props.textColor,
                fontSize: 14,
                marginBottom: 6,
                marginTop: 6,
                fontWeight: 1000,
                maxWidth: 150,
                textAlign: "center",
              }}
            >
              {props.symbol}
            </p>
          </div>
        ) : null}
      </div> )}

    {selected && (
    <div
      style={{
        backgroundColor: "#EB3737",
        backgroundImage: `url(${imageURL})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.8,
        width: '120px',
        height: '120px',
      }}
      className={"companyBubble"}
      onClick={bubblePressed}
      on
    >
      {true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            transition: "opacity 0.1s ease",
            opacity: props.bubbleSize > 50 ? 1 : 0,
            pointerEvents: "none",
          }}
        >
          <BiBus size={"45px"} color={props.textColor} style={{transition: 'opacity 1s ease', opacity: 1 }} />
          <p
            style={{
              color: props.textColor,
              fontSize: 14,
              marginBottom: 6,
              MarginTop: 10,
              fontWeight: 1000,
              maxWidth: 150,
              textAlign: "center",
            }}
          >
            {props.symbol}
          </p>
        </div>
      ) : null}
    </div> )}
  </div>
  );
}
