import React from "react";

import { ChakraProvider } from "@chakra-ui/react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import GetStarted from "./GetStarted";
import Selection from "./Selection";
import Complete from "./Complete";
import Error from "./Error";


class App extends React.Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<GetStarted />} />
          <Route path="/selection" element={<Selection />} />
          <Route path="/complete" element={<Complete />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    );
  }
}

export default App;